<template>
  <section>
    <TitleBar>
      {{ titre }}
      <em
        v-if="modified"
        class="d-print-none text-danger"
        style="font-weight: bold; font-size: initial"
      >
        modifications non enregistr&eacute;es
      </em>
      <template #toolbar-content>
        <b-button-group v-if="modified">
          <b-button
            class="rounded-left"
            variant="primary"
            @click="validate(commande.etape)"
          >
            Enregistrer
          </b-button>
          <b-button
            class="rounded-right"
            variant="outline-primary"
            @click="load()"
          >
            <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
            Recharger
          </b-button>
        </b-button-group>
        &nbsp;
        <b-button-group>
          <b-button pill variant="outline-primary" @click="cancel">
            <b-icon-arrow-left></b-icon-arrow-left>
            Retour
          </b-button>
        </b-button-group>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <div v-if="catalogue == null" class="text-center text-warning my-3">
        <b-spinner class="align-middle"></b-spinner>
        <br />
        <strong>Chargement en cours...</strong>
      </div>
      <div
        v-else-if="catalogue.listesProduits.length === 0"
        class="text-center my-3"
      >
        Ce catalogue ne contient encore aucune liste de produits.
        <br />
        Acc&eacute;der &agrave; Strapi pour les cr&eacute;er.
      </div>
      <template v-else>
        <!-- Contenu statique en entête -->
        <section v-if="catalogue.entete.length > 0" class="mb-3">
          <StrapiLigneComponent
            v-for="ligne in catalogue.entete"
            :key="ligne.id"
            :item="ligne"
          ></StrapiLigneComponent>
        </section>
        <!-- Champs de formulaire pour l'impression -->
        <section class="d-none d-print-block mb-3 break-page">
          <b-row>
            <b-col cols="6" style="border-right: 1px solid lightgrey">
              <b-form-group label="" label-cols="auto" class="text-center">
                <b-form-radio-group :checked="null">
                  <b-form-radio>
                    Je souhaite r&eacute;cup&eacute;rer ma commande en
                    d&eacute;p&ocirc;t
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Dépôt :">
                <b-form-checkbox-group>
                  <template v-for="depot in optionsDepots">
                    <b-form-checkbox
                      v-if="!!depot.value"
                      :key="depot.value"
                      :value="depot.value"
                    >
                      {{ depot.text }}
                    </b-form-checkbox>
                  </template>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="" label-cols="auto" class="text-center">
                <b-form-radio-group :checked="null">
                  <b-form-radio>
                    Je souhaite &ecirc;tre livr&eacute; &agrave; l'adresse de
                    mon choix
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <CustomInputComponent
                label="Raison sociale :"
                value=""
              ></CustomInputComponent>
              <CustomInputComponent
                label="Adresse 1 :"
                value=""
              ></CustomInputComponent>
              <CustomInputComponent
                label="Adresse 2 :"
                value=""
              ></CustomInputComponent>
              <SelectCommuneComponent
                label="Commune :"
                :value="0"
              ></SelectCommuneComponent>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <InputCommentaireComponent
                label="Commentaire :"
                :showIfEmpty="true"
                value=""
              ></InputCommentaireComponent>
            </b-col>
          </b-row>
        </section>
        <!-- Champs de formulaire pour la page de commande -->
        <b-row class="d-print-none mb-3">
          <b-col cols="12" md="6" style="border-right: 1px solid lightgrey">
            <b-form-group
              label="Je souhaite : "
              label-cols="12"
              label-cols-md="3"
            >
              <b-form-radio-group
                :disabled="commandeVerrouillee"
                :checked="livraisonDirecte"
                @input="
                  (value) => {
                    livraisonDirecteChanged(value);
                    modified = true;
                  }
                "
              >
                <b-form-radio :value="false">
                  r&eacute;cup&eacute;rer ma commande en d&eacute;p&ocirc;t
                </b-form-radio>
                <b-form-radio :value="true">
                  &ecirc;tre livr&eacute; &agrave; l'adresse de mon choix
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <template v-if="livraisonDirecte">
              <CustomInputComponent
                ref="inputRaisonSociale"
                label="Raison sociale :"
                :labelCols="{ md: 3 }"
                :disabled="commandeVerrouillee"
                v-model="commande.nomUsage"
                @input="modified = true"
              ></CustomInputComponent>
              <CustomInputComponent
                ref="inputAdresse1"
                label="Adresse 1 :"
                :labelCols="{ md: 3 }"
                :disabled="commandeVerrouillee"
                v-model="commande.adresse1"
                @input="modified = true"
              ></CustomInputComponent>
              <CustomInputComponent
                ref="inputAdresse2"
                label="Adresse 2 :"
                :labelCols="{ md: 3 }"
                :disabled="commandeVerrouillee"
                v-model="commande.adresse2"
                @input="modified = true"
              ></CustomInputComponent>
              <SelectCommuneComponent
                ref="inputCommune"
                label="Commune :"
                :labelCols="{ md: 3 }"
                :disabled="commandeVerrouillee"
                v-model="commande.idCommune"
                @input="modified = true"
              ></SelectCommuneComponent>
              <div class="text-center">
                <b-button pill variant="primary" @click="openAdresseDialog">
                  <b-icon-geo-alt-fill></b-icon-geo-alt-fill>
                  &nbsp;Choisir une adresse existante
                </b-button>
              </div>
            </template>
            <CustomSelectComponent
              v-else
              ref="selectDepotAppro"
              label="Dépôt :"
              :labelCols="{ xs: 12, md: 3 }"
              :disabled="commandeVerrouillee"
              rules="required"
              :options="optionsDepots"
              v-model="commande.codeDepot"
              @input="modified = true"
            ></CustomSelectComponent>
          </b-col>
          <b-col
            v-if="!commandeVerrouillee || commande.commentaire"
            cols="12"
            md="6"
          >
            <InputCommentaireComponent
              label="Commentaire :"
              :labelCols="{ md: 'auto' }"
              :showIfEmpty="true"
              :size="1000"
              :disabled="commandeVerrouillee"
              v-model="commande.commentaire"
              @input="modified = true"
            ></InputCommentaireComponent>
          </b-col>
        </b-row>
        <template v-for="(liste, index) in catalogue.listesProduits">
          <CategorieComponent
            class="break-page"
            :ref="`liste-${liste.idListe}`"
            :key="liste.idListe"
            icon="basket"
            :titre="liste.titre"
            :visible="index === 0"
            @show="hideAllExcept(liste.idListe)"
          >
            <!-- Contenu statique en entête de catégorie -->
            <section v-if="liste.entete.length > 0" class="mb-3">
              <StrapiLigneComponent
                v-for="ligne in liste.entete"
                :key="ligne.id"
                :item="ligne"
              ></StrapiLigneComponent>
            </section>
            <!-- Tableau de commande des produits -->
            <UnpaginatedTable
              :fields="liste.fields"
              :items="liste.produits"
              :sortBy="null"
              :sortDesc="false"
            >
              <template
                v-for="(carac, index) in liste.caracs"
                #[`cell(carac_${carac.idCarac})`]="{ item }"
              >
                <span
                  v-if="carac.type === TypeCarac.INFO_LIBRE.value"
                  :key="carac.idCarac"
                >
                  <template v-if="item.caracs[carac.idCarac]">
                    <LongTextComponent
                      :value="item.caracs[carac.idCarac].valeur"
                    ></LongTextComponent>
                  </template>
                </span>
                <span
                  v-else-if="carac.type === TypeCarac.INFO_AUTO.value"
                  :key="carac.idCarac"
                >
                  <template v-if="item.caracs[carac.idCarac]">
                    <LongTextComponent
                      :value="item.caracs[carac.idCarac].valeur"
                    ></LongTextComponent>
                  </template>
                  <template v-else-if="carac.champSource === 'AB_NT'">
                    {{ item.produit.libelle.includes(" NT ") ? "NT" : "AB" }}
                  </template>
                  <template v-else-if="carac.champSource === 'DESCRIPTION'">
                    <LongTextComponent
                      :value="item.produit.description"
                    ></LongTextComponent>
                  </template>
                  <template v-else-if="carac.champSource === 'PRIX'">
                    <!-- TODO Calculer le prix correspondant au tiers connecté -->
                    <em>Inconnu</em>
                  </template>
                </span>
                <span
                  v-else-if="carac.type === TypeCarac.SAISIE_AUTRE.value"
                  :key="carac.idCarac"
                >
                  <template v-if="carac.champDestination === 'DATE_SEMIS'">
                    <CustomSelectComponent
                      :ref="`input-date-semis-${item.idProduit}`"
                      v-if="!item.commentaireDisabled()"
                      class="d-print-none"
                      label=""
                      :disabled="commandeVerrouillee"
                      :options="datesSemis"
                      rules="required"
                      v-model="item.dateSemis"
                      @input="modified = true"
                    ></CustomSelectComponent>
                  </template>
                  <div class="d-none d-print-block border">&nbsp;</div>
                </span>
                <span
                  v-else-if="carac.type === TypeCarac.SAISIE_QUANTITE.value"
                  :key="carac.idCarac"
                >
                  <template v-if="item.conditionnements[carac.idCarac]">
                    <CustomInputNumberComponent
                      :ref="`input-quantite-${
                        item.conditionnements[carac.idCarac].idConditionnement
                      }`"
                      class="d-print-none"
                      label=""
                      :append="
                        item.conditionnements[carac.idCarac].produit.uniteVente
                      "
                      :step="
                        item.conditionnements[carac.idCarac].produit
                          .multipleVente
                      "
                      :disabled="
                        commandeVerrouillee ||
                        !item.conditionnements[carac.idCarac].commandable
                      "
                      :rules="`min_value:0|multiple:${
                        item.conditionnements[carac.idCarac].produit
                          .multipleVente
                      }`"
                      v-model="
                        item.conditionnements[carac.idCarac].ligneCommande
                          .quantiteCommandee
                      "
                      @input="modified = true"
                    ></CustomInputNumberComponent>
                    <div class="d-none d-print-block border text-right">
                      {{
                        item.conditionnements[carac.idCarac].produit.uniteVente
                      }}
                    </div>
                  </template>
                  <em v-else class="d-none d-print-inline">Indisponible</em>
                </span>
                <StatutCommandeIcon
                  v-if="index === 0"
                  :key="`statut-${carac.idCarac}`"
                  :statut="item.statut"
                  :message="item.messageStatut"
                ></StatutCommandeIcon>
              </template>
              <template #cell(actions)="{ item }">
                <div class="actions-cell">
                  <CommentaireComponent
                    :disabled="item.commentaireDisabled()"
                    :value="item.commentaire"
                    @click="openCommentaireEditor(item)"
                  ></CommentaireComponent>
                </div>
              </template>
            </UnpaginatedTable>
            <!-- Contenu statique en pied de catégorie -->
            <section v-if="liste.entete.length > 0" class="mb-3">
              <StrapiLigneComponent
                v-for="ligne in liste.contenu"
                :key="ligne.id"
                :item="ligne"
              ></StrapiLigneComponent>
            </section>
          </CategorieComponent>
        </template>
        <section v-if="catalogue.contenu.length > 0" class="mb-3">
          <StrapiLigneComponent
            v-for="ligne in catalogue.contenu"
            :key="ligne.id"
            :item="ligne"
          ></StrapiLigneComponent>
        </section>
        <section class="d-print-none mb-3 text-center">
          <template v-if="commandeVerrouillee">
            <b-button pill variant="outline-primary" @click="cancel">
              <b-icon-arrow-left></b-icon-arrow-left>
              Retour
            </b-button>
          </template>
          <template v-else>
            <b-button
              pill
              variant="outline-primary"
              @click="validate(EtapeCommande.EN_COURS.name)"
            >
              Enregistrer comme brouillon
            </b-button>
            &nbsp;
            <b-button
              pill
              variant="primary"
              @click="validate(EtapeCommande.VALIDEE.name)"
            >
              Valider ma commande
            </b-button>
          </template>
        </section>
      </template>
    </b-container>
    <SelectAdresseDialog ref="selectAdresseDialog"></SelectAdresseDialog>
    <CommentaireDialog ref="commentaireEditionDialog"></CommentaireDialog>
    <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StrapiLigneComponent from "../../components/strapi/StrapiLigneComponent.vue";
import CustomInputComponent from "../../components/inputs/CustomInputComponent.vue";
import SelectCommuneComponent from "../../components/inputs/SelectCommuneComponent.vue";
import InputCommentaireComponent from "../../components/inputs/InputCommentaireComponent.vue";
import UnpaginatedTable from "../../components/UnpaginatedTable.vue";
import CategorieComponent from "../../components/appro/CategorieComponent.vue";
import LongTextComponent from "../../components/LongTextComponent.vue";
import CustomSelectComponent from "../../components/inputs/CustomSelectComponent.vue";
import CustomInputNumberComponent from "../../components/inputs/CustomInputNumberComponent.vue";
import StatutCommandeIcon from "../../components/appro/icons/StatutCommandeIcon.vue";
import CommentaireComponent from "../../components/controls/CommentaireComponent.vue";
import SelectAdresseDialog from "../../components/dialogs/SelectAdresseDialog.vue";
import CommentaireDialog from "../../components/dialogs/CommentaireDialog.vue";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog.vue";
import ApproService, {
  TypeCarac,
  EtapeCommande,
  methods,
} from "../../services/appro.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "CommandeApproView",
  components: {
    TitleBar,
    ErrorComponent,
    StrapiLigneComponent,
    CustomInputComponent,
    SelectCommuneComponent,
    InputCommentaireComponent,
    UnpaginatedTable,
    CategorieComponent,
    LongTextComponent,
    CustomSelectComponent,
    CustomInputNumberComponent,
    StatutCommandeIcon,
    CommentaireComponent,
    SelectAdresseDialog,
    CommentaireDialog,
    ConfirmationDialog,
  },
  data: () => ({
    error: null,
    catalogue: null,
    livraisonDirecte: false,
    depotDirect: null,
    depotsRelais: [],
    commande: null,
    produitsGrises: [],
    modified: false,
    TypeCarac,
    EtapeCommande,
    datesSemis: methods.getDatesSemis(),
  }),
  computed: {
    titre() {
      return this.catalogue == null
        ? "Chargement en cours..."
        : this.catalogue.titre;
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    commandeVerrouillee() {
      return (
        this.catalogue?.ouvert !== true ||
        this.commande?.etape !== EtapeCommande.EN_COURS.name
      );
    },
    optionsDepots() {
      return [
        { value: null, text: "" },
        ...this.depotsRelais.map((d) => ({
          value: d.codeSite,
          text: d.libelle,
        })),
      ];
    },
  },
  async mounted() {
    await this.$store.dispatch("ref/revalidateAppro");

    // Charger les dépôts de la société courante
    let depotsAppro = this.$store.getters["ref/depotsAppro"](
      this.exploitationCourante?.codeSociete
    )
      .filter((d) => !!d.actif)
      .sort(UtilsService.sortByStringProperty("libelle"));
    this.depotDirect = depotsAppro.find((d) => !!d.livraisonDirecte);
    this.depotsRelais = depotsAppro.filter((d) => !d.livraisonDirecte);

    await this.load();
  },
  watch: {
    $route() {
      this.load();
    },
  },
  methods: {
    ...methods,
    fields(liste) {
      return [
        ...liste.caracs.map((c) => ({
          key: `carac_${c.idCarac}`,
          label: c.libelle,
          thStyle: `width: ${c.largeur}`,
        })),
        {
          key: "actions",
          label: "",
          thStyle: { width: "120px" },
          class: "d-print-none",
        },
      ];
    },
    async load() {
      // Réinitialiser l'état du composant
      this.error = null;
      this.catalogue = null;
      this.livraisonDirecte = false;
      this.commande = null;
      this.produitsGrises = [];
      this.modified = false;

      // Récupérer les infos du catalogue et de la commande depuis l'API
      try {
        let catalogue = null;
        let commande = null;
        if (this.$route.params.idCommande) {
          let results = await Promise.all([
            ApproService.getCatalogue(this.$route.params.id),
            ApproService.getCommande(this.$route.params.idCommande),
          ]);
          catalogue = results[0].data;
          commande = results[1].data;
        } else {
          let response = await ApproService.getCatalogue(this.$route.params.id);
          catalogue = response.data;
          commande = this.emptyCommandeExtranet();
          commande.idCatalogue = this.$route.params.id;
          commande.codeTiersFacture = this.exploitationCourante.codeTiers;
        }

        // Filtrer complètement les listes vides
        catalogue.listesProduits = catalogue.listesProduits.filter(
          (l) => l.produits.length > 0
        );

        // Enrichir les données pour l'affichage
        catalogue.listesProduits.forEach((l) => {
          // Préparer la liste des champs pour chaque liste
          l.fields = this.fields(l);

          // Filtrer les produits sans conditionnements
          l.produits = l.produits.filter(
            (p) => Object.values(p.conditionnements).length > 0
          );

          l.produits.forEach((p) => {
            // Préparer les données de chaque conditionnement
            Object.values(p.conditionnements).forEach((c) => {
              // Charger le produit appro
              c.produit = this.$store.getters["ref/produitAppro"](
                c.codeProduit
              );

              // Charger ou créer la ligne de commande
              let ligne = commande.lignes.find(
                (l) => l.idConditionnement === c.idConditionnement
              );
              if (!ligne) {
                ligne = {
                  ...this.emptyLigneCommandeExtranet(),
                  idConditionnement: c.idConditionnement,
                };
                commande.lignes.push(ligne);
              }
              c.ligneCommande = ligne;

              // Identifier les quantités > 0 sur produits grisés
              if (!c.commandable && c.ligneCommande.quantiteCommandee > 0) {
                this.produitsGrises.push(c);
              }

              // Remonter les champs nécessaires au niveau du produit
              p.produit = p.produit ?? c.produit;
              p.dateSemis = p.dateSemis ?? ligne.dateSemis;
              p.commentaire = p.commentaire ?? ligne.commentaire;
            });

            // Fonction pour savoir si on peut saisir ou non un commentaire
            p.commentaireDisabled = () =>
              !Object.values(p.conditionnements).find(
                (c) => c.commandable && c.ligneCommande.quantiteCommandee > 0
              );
          });
        });

        // (Ré)initialiser le flag de livraison directe et le dépôt
        this.livraisonDirecte =
          commande.codeDepot === this.depotDirect.codeSite;
        if (!this.livraisonDirecte && this.depotsRelais.length === 1) {
          commande.codeDepot = this.depotsRelais[0]?.codeSite;
        }

        this.commande = commande;
        this.catalogue = catalogue;
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
    livraisonDirecteChanged(value) {
      this.livraisonDirecte = value;
      if (value) {
        this.commande.codeDepot = this.depotDirect.codeSite;
      } else {
        this.commande.codeDepot =
          this.depotsRelais.length === 1 ? this.depotsRelais[0].codeSite : null;
        this.commande.adresseLivraison = null;
      }
    },
    hideAllExcept(idListe) {
      this.catalogue.listesProduits
        .filter((l) => l.idListe !== idListe)
        .forEach((l) => this.$refs[`liste-${l.idListe}`][0].hide());
    },
    async openAdresseDialog() {
      let adresse = await this.$refs.selectAdresseDialog.show({
        codeTiers: this.exploitationCourante.codeTiers,
        excludeTypes: ["CONTACT"],
      });
      if (adresse != null) {
        this.commande.nomUsage = adresse.nomUsage;
        this.commande.adresse1 = adresse.adresse1;
        this.commande.adresse2 = adresse.adresse2;
        this.commande.idCommune = adresse.commune?.idCommune;
        this.modified = true;
      }
    },
    async openCommentaireEditor(produit) {
      let resultat = await this.$refs.commentaireEditionDialog.show({
        labelItems: "Produits",
        items: Object.values(produit.conditionnements).map(
          (c) => c.produit.libelle
        ),
        labelCommentaire: "Commentaire",
        description:
          "Utilisez cet espace pour transmettre une information au responsable appro de votre coopérative.",
        size: 1000,
        disabled: this.commandeVerrouillee,
        commentaire: produit.commentaire,
      });
      if (resultat != null) {
        produit.commentaire = resultat;
        this.modified = true;
      }
    },
    async cancel() {
      if (this.modified) {
        let confirmation = await this.$refs.confirmationDialog.show({
          title: "Quitter sans enregistrer ?",
          messages: [
            `Vous avez des modifications non enregistrées ! Etes-vous sûr de vouloir quitter cet écran sans enregistrer ?`,
          ],
        });
        if (!confirmation) {
          return;
        }
      }
      if (this.$route.params.idCommande) {
        this.$router.push({ name: "commandes-appro-extranet" });
      } else {
        this.$router.push({ name: "catalogues" });
      }
    },
    async validate(etapeCommande) {
      // Valider l'entête de commande
      await this.$refs.selectDepotAppro?.validate();

      for (const l of this.catalogue.listesProduits) {
        for (const p of l.produits) {
          // Valider les dates de semis et les quantités
          let inputs = this.$refs[`input-date-semis-${p.idProduit}`] ?? [];
          inputs.push(
            ...Object.values(p.conditionnements).map(
              (c) => this.$refs[`input-quantite-${c.idConditionnement}`][0]
            )
          );
          for (const input of inputs) {
            try {
              await input.validate();
            } catch (e) {
              this.$refs[`liste-${l.idListe}`][0].show();
              this.hideAllExcept(l.idListe);
              await this.$nextTick();
              input.focus();
              return;
            }
          }
        }
      }

      // Intercepter les commandes avec des produits grisés
      if (
        this.produitsGrises.length > 0 &&
        etapeCommande === EtapeCommande.VALIDEE.name
      ) {
        let confirmation = await this.$refs.confirmationDialog.show({
          title: "Annuler ces lignes de commande ?",
          messages: [
            `Les produits suivants ont été grisés depuis la saisie initiale de votre commande et ne sont actuellement plus disponibles :`,
            ...this.produitsGrises.map((c) => {
              return `- ${c.produit.libelle} (${c.ligneCommande.quantiteCommandee}${c.produit.uniteVente})`;
            }),
            `Si vous continuez, ils seront supprimés de votre commande avant transmission à votre coopérative. Confirmez-vous ?`,
          ],
        });
        if (!confirmation) {
          return;
        }

        // Remettre à 0 les lignes concernées
        this.produitsGrises.forEach(
          (c) => (c.ligneCommande.quantiteCommandee = 0)
        );
      }

      // Recopier les informations saisies 1x par ligne
      // Du produit vers ses conditionnements
      this.catalogue.listesProduits.forEach((l) => {
        l.produits.forEach((p) => {
          Object.values(p.conditionnements).forEach((c) => {
            c.ligneCommande.dateSemis = p.dateSemis;
            c.ligneCommande.commentaire = p.commentaire;
          });
        });
      });

      this.commande.etape = etapeCommande;
      this.error = null;
      try {
        let commande = UtilsService.deepCopy(this.commande);
        await ApproService.createUpdateCommande(commande);
        this.$router.push({ name: "commandes-appro-extranet" });
      } catch (error) {
        this.error = UtilsService.handleError(error);
      }
    },
  },
};
</script>
